import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import WeightliftingView from '@/views/WeightliftingView.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/wl',
		name: 'weightlifting',
		component: WeightliftingView
	}
]

const router = createRouter({ history: createWebHistory(), routes })
export default router
