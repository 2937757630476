<template>
	<div class="container p-0 mt-2">
		<div class="row">
			<div class="col-xl-9 mx-auto d-flex flex-column">
				<div class="card mb-3" style="max-width: 1200px; background-color: inherit;">
					<div class="row no-gutters">
						<div class="col-md-4">
							<img src="./../assets/cover.png" class="card-img img-fluid h-100" alt="...">
						</div>
						<div class="col-md-8">
							<div class="card-body" style="color: #000">
								<h4 class="card-title">Halldór Jens Vilhjálmsson</h4>
								<h5 class="card-subtitle">BSc in Computer Science</h5>
								<p class="card-text mt-1">Hello! I'm Halldór Jens, a 26 year old Computer Scientist. I achieved my Bachelor's Degree from the University of Iceland in 2023. I'm highly enthusiastic about backend programming and possess a deep interest in matters related to cybersecurity.</p>
								<p class="card-text">I am a dedicated individual with a proven track record of success in both my professional and athletic pursuits. My achievements in powerlifting, particularly in the junior category, speak to my commitment and drive. My passion for the sport extends beyond competition, and I am continually seeking opportunities to expand my knowledge and understanding in this area.</p>
								<!--<p><i class="fa-brands fa-linkedin fa-2x"></i><a href="www.hjv.is">test</a></p>-->
								<div class="contact d-flex justify-content-center">
									<a class="text-decoration-none text-black fs-3 m-1" href="https://www.linkedin.com/in/halldorjv" style="color: #000;"><i class="fa-brands fa-linkedin" style="color: #000;"/> halldorjv</a>
									<a class="text-decoration-none text-black fs-3 m-1" href="https://github.com/dorijv"><i class="fa-brands fa-square-github" style="color: #000;"/> dorijv</a>
									<a class="text-decoration-none text-black fs-3 m-1" href="mailto:hjv6@hi.is"><i class="fa-solid fa-envelope" style="color: #000;"/> hjv6@hi.is</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-9 mx-auto d-flex flex-column">
				<ProjectsComponent />
			</div>
		</div>
	</div>
</template>

<script>
	import ProjectsComponent from '@/components/ProjectsComponent.vue'

	export default {
		name: 'HomeView',
		components: {
			ProjectsComponent
		}
	}
</script>