<template>
	<footer>
		
	</footer>
</template>

<script>
	export default {
		name: 'FooterComponent'
	}
</script>