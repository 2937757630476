<template>
	<div class="card-group" style="background-color: inherit;">
		<div class="card" style="background-color: inherit;">
			<img src="./../assets/isv.png" class="card-img-top" style="height: 230px;" alt="...">
			<div class="card-body" style="color: #000;">
				<h5 class="card-title">South - Isavia</h5>
				<p class="card-text">During my time as an Airport Security employee, I utilized my free time to develop a simple web-based platform aimed at enhancing the efficiency and collaboration of gate swaps for both Schengen and non-Schengen flights. The goal was to provide a more streamlined and user-friendly solution for all parties involved.</p>
			</div>
			<div class="card-footer">
				<small class="text-muted"><a href="https://github.com/dorijv/South-Isavia" class="text-decoration-none text-black"><i class="fa-brands fa-square-github"/> Repository</a></small>
			</div>
		</div>

		<div class="card" style="background-color: inherit;">
			<img src="./../assets/taem.png" class="card-img-top" style="height: 230px;" alt="...">
			<div class="card-body" style="color: #000;">
				<h5 class="card-title">TÆM</h5>
				<p class="card-text">A fully functional time tracking system was developed as part of my group assignments in the courses HBV501G and HBV601G. It utilizes advanced technologies including Java Spring, Thymeleaf, Railway hosting, and bcrypt encryption for secure and efficient data processing, storage and user-friendly interface. This system streamlines the time tracking process for any workplace, ensuring accurate tracking and management.</p>
			</div>
			<div class="card-footer">
				<small class="text-muted"><a href="https://github.com/sammimagg/HBV501G-GROUP-8" class="text-decoration-none text-black"><i class="fa-brands fa-square-github"/> Repository</a></small>
			</div>
		</div>

		<div class="card" style="background-color: inherit;">
			<img src="./../assets/wec.png" class="card-img-top" style="height: 230px;" alt="...">
			<div class="card-body" style="color: #000;">
				<h5 class="card-title">WEC</h5>
				<p class="card-text">I recently developed a simplistic informational website for Njarðvík in preparation for their upcoming Western European Championships in Powerlifting. This website was created as a modern and interactive alternative to the traditional printed pamphlets, offering a more comprehensive and accessible source of information for participants and attendees.</p>
			</div>
			<div class="card-footer">
				<small class="text-muted"><a href="https://github.com/dorijv/wec" class="text-decoration-none text-black"><i class="fa-brands fa-square-github"/> Repository</a></small>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ProjectsComponent'
	}
</script>