<template>
	<header>
		<nav class="navbar navbar-expand-lg" data-bs-theme="dark" style="background-color: #153243; color: #1C3144;"> <!-- Prussian -->
			<!-- #1F0318 273043; -->
			<div class="d-lg-flex mx-lg-auto " style="max-width: 980px; width: 100%;">
				<div class="d-flex justify-content-between mx-1">
					<a class="navbar-brand fs-2 text-start mx-lg-auto" hef="#">
						<!--<img src="./../assets/letter-h.png" alt="Logo" width="32" height="32" class="d-inline-block">-->
						Halldór Jens
					</a>
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
				</div>

				<div class="collapse navbar-collapse fs-5" id="navbarText">
					<ul class="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end d-flex w-100">
						<li class="d-flex nav-item align-items-center">
							<router-link to="/" class="m-2 text-decoration-none text-monospace text-light-emphasis">Computer Science</router-link>
						</li>
						<li class="d-flex nav-item align-items-center">
							<router-link to="/wl" class="m-2 text-decoration-none text-monospace text-light-emphasis">Weightlifting</router-link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
	export default {
		name: 'NavigationBar'
	}
</script>