<template>
	<div>
		<p>Coming Soon :)</p>
	</div>
</template>

<script>
	export default {
		name: 'WeightliftingView'
	}
</script>